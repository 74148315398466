import { setSpottingsListener} from './utils';
import './App.css';
import { Layout, Row, Col } from 'antd';
import "antd/dist/antd.css";
import React from 'react';
import { CustomUpload } from "./CustomUpload";
import { MapView } from "./MapView";
import { SpottingTimeline } from "./SpottingTimeline"

/*
60 min:
tiktok
- crop to 3 dances I LIKE
- make tiktok of 3 dances
- ali to send song
- 

20 min
make hassan dictionary mobile responsive
*/

const { Header, Content, Footer } = Layout;

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      spottings: [],
      selectedSpottingIdx: 0,
    }
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
    setSpottingsListener((spottings) => {
      spottings.sort((s1, s2) => s2.created_at.seconds - s1.created_at.seconds);
      this.setState({spottings, selectedSpottingIdx: spottings.length-1});
    })
  }

  updateSelectedSpotting = (idx) => {
    this.setState({selectedSpottingIdx: idx});
  }

  render() {
    console.log(this.state.spottings);
    const { spottings, selectedSpottingIdx } = this.state;
    return (

      <div className="bg-white text-gray-900 text-md sm:text-lg">
        <header className="container mx-auto p-4 flex justify-between items-center gap-4 flex-col sm:flex-row">
          <a href="home.html">
            <img src={process.env.PUBLIC_URL + 'img/logo-name-new.png'} className="h-10" />
          </a>
          <nav className="flex gap-12 sm:gap-16">
            <a className="link" href={process.env.PUBLIC_URL + 'about.html'} style={{color: "black"}}>About</a>
            <a className="link" href={process.env.PUBLIC_URL + 'privacy.html'} style={{color: "black"}}>Privacy</a>
          </nav>
        </header>
        <section
          className="flex flex-col gap-8 items-center justify-center p-4 bg-opacity-20 bg-cover bg-center"
          style={{backgroundImage: "url('" + process.env.PUBLIC_URL + "/img/cover-photo-no-text.png')"}}>
          <div className="max-w-3xl mx-auto p-2 text-center text-4xl leading-snug md:text-6xl md:leading-snug font-semibold">
            Crowd-sourcing Hassan's location, one image at a time.
          </div>
          <p className="font-semibold text-center text-2xl leading-relaxed max-w-3xl text-gray-500">You tell us when you've seen Hassan, we'll tell you whenever he's been seen.</p>
          <p className="font-semibold text-center text-2xl leading-relaxed max-w-3xl text-gray-500">Live alerts, historic maps, and more.</p>
        </section>
        <div className="container mx-auto p-4">
          <Row>
            {/* <Col xs={24} xl={1} /> */}
            <Col xs={24} xl={9} style={{marginBottom: "8px"}}> <SpottingTimeline spottings={spottings}/> </Col>
            <Col xs={24} xl={1} />
            <Col xs={24} xl={14}> <MapView spottings={spottings} selectedSpottingIdx={selectedSpottingIdx}/> </Col>
          </Row>
        </div>
      </div>
    );
  }
  
}

export default App;
