import React from 'react';
import {storage, addSpotting, sendSpottingEmail} from "./utils";
import { ref, uploadBytes } from "firebase/storage";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Tag } from "antd";

export class CustomUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, imageUrl: '' };
    }

    onChange = (input) => {
        const reader = new FileReader();
        const imageName = Math.random().toString(16).substr(2);
        reader.onload = (e) => {
            fetch(e.target.result).then((res) => res.blob()).then((blob) => {
                const file = new File([blob], "myfile", { type: 'image/png' });
                const storageRef = ref(storage, `spottings/${imageName}.png`);
                return uploadBytes(storageRef, file);
            }).then((snapshot) => {
                console.log(snapshot.ref);
                console.log('Uploaded a blob or file!');
                const imageUrl = `https://firebasestorage.googleapis.com/v0/b/where-is-hassan-kane.appspot.com/o/spottings%2F${imageName}.png?alt=media`;
                this.setState({loading: false, imageUrl });
                return navigator.geolocation.getCurrentPosition(async (position) => {

                    const lat = position.coords.latitude;
                    const long = position.coords.longitude;
                    console.log("Latitude is :", lat);
                    console.log("Longitude is :", long);
                    const locationURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyAwIg80fqoURfNdiV-4iT13I18ozGpoaao`;
                    const locationResp = await fetch(locationURL);
                    const locationRespJson = await locationResp.json();
                    console.log(locationRespJson);
                    let displayLocation;
                    if (locationRespJson.results && locationRespJson.results.length > 0 && locationRespJson.results[0].formatted_address) {
                        displayLocation = locationRespJson.results[0].formatted_address;
                    }
                    addSpotting(lat, long, displayLocation, imageUrl);
                    sendSpottingEmail(e.target.result, displayLocation);
                });
            });
        };
        this.setState({loading: true})
        reader.readAsDataURL(input.target.files[0]);
    }
    
    render () {
      const { loading, imageUrl } = this.state;
      if (loading) {
          return <LoadingOutlined style={{color: "#1890ff"}}/>;
      }
      return (
        <label onChange={this.onChange} htmlFor="formId" style={{
            cursor: "pointer",
        }}>
            <input name="" type="file" id="formId" accept="image/*" hidden />
            <Tag icon={<PlusOutlined style={{verticalAlign: "0rem"}}/>} color="#1890ff">
                Upload New Spotting!!
            </Tag>
        </label>
      );
    }
  }