import React from 'react';
import GoogleMapReact from "google-map-react";
import { Avatar } from "antd";
import { FlagFilled } from "@ant-design/icons"

const SELECTED_COLOR = '#87d068';
const UNSELECTED_COLOR = '#f56a00';

export class MapView extends React.Component {
  
    render() {
      return (
        <div style={{ height: '60vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAwIg80fqoURfNdiV-4iT13I18ozGpoaao"}}
                defaultCenter={{lat: 30.9239219, lng: -31.4935242}}
                defaultZoom={0}
            >
                {this.props.spottings.map((spotting, idx) => {
                    return <MapMarker
                        key={spotting.image_url}
                        lat={spotting.location.latitude}
                        lng={spotting.location.longitude}
                        idx={this.props.spottings.length - idx}
                        text="My Marker"
                    />
                })}
                
            </GoogleMapReact>
        </div>
      );
    }
    
}

class MapMarker extends React.Component {
  
    render() {
      return (
         <div style={{ transform: 'translate(-50%, -50%)', cursor: "pointer", position: "relative" }}>
            <Avatar style={{backgroundColor: '#f56a00'}}>{this.props.idx}</Avatar>
         </div>
      );
    }
}