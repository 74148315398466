import { initializeApp } from 'firebase/app';
import { getFirestore, GeoPoint, FieldValue, serverTimestamp } from "firebase/firestore";
import { collection, addDoc, getDocs, query, onSnapshot } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import emailjs from 'emailjs-com';

const DOCNAME = "spottings-prod";

const firebaseConfig = {
    apiKey: "AIzaSyB18nD_Rgql85yzRshrLrZyZS-vh1W50Ec",
    authDomain: "where-is-hassan-kane.firebaseapp.com",
    projectId: "where-is-hassan-kane",
    storageBucket: "where-is-hassan-kane.appspot.com",
    messagingSenderId: "724202805076",
    appId: "1:724202805076:web:4a8116080aaadc62c4dac1",
    measurementId: "G-VRMNBWNTCB"
};

export const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore();
export const storage = getStorage();

export const addSpotting = async (latitude, longitude, displayLocation, imageURL) => {
    try {
        const docRef = await addDoc(collection(db, DOCNAME), {
            image_url: imageURL,
            location: new GeoPoint(latitude, longitude),
            created_at: serverTimestamp(),
            display_location: displayLocation,
        });
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const getAllSpottings = () => {
    const q = query(collection(db, DOCNAME));
    const querySnapshot = getDocs(q);
    return querySnapshot.then((docs) => {
        docs.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
        })
    })
}

export const setSpottingsListener = (callback) => {
    const q = query(collection(db, DOCNAME));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const spottings = [];
        querySnapshot.forEach((doc) => {
            const d = doc.data();
            if (d.created_at) {
                spottings.push(d);
            }
        });
        callback(spottings);
    });
    return unsubscribe;
}

export const sendSpottingEmail = (imageFile, locationStr) => {
    const serviceID = 'default_service';
    const templateID = 'template_o3c8b1l';
    emailjs.send(serviceID, templateID ,{
        location: locationStr,
        hassan_image: imageFile.split("base64,")[1]
    }, 'user_K1ASrhvf782XKYvSu2Bxg').then((response) => {
        console.log('SUCCESSFULLY SENT EAMIL!', response.status, response.text);
    }, (err) => {
        console.error('FAILED TO SEND EAMIL...', err);
    });
}