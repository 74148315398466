import React from 'react';
import { Timeline } from "antd";
import { CustomUpload } from "./CustomUpload";

export class SpottingTimeline extends React.Component {
    
    render () {
      return (
        <Timeline style={{ overflowY: "auto", height: "60vh", padding: "4px" }}>
          <Timeline.Item style={{marginTop: "4px" }}>
            <CustomUpload />
          </Timeline.Item>
          {this.props.spottings.map((spotting) => {
            return <Timeline.Item>
              <p>{(new Date(spotting.created_at.seconds*1000)).toDateString()}</p>
              {spotting.display_location && <p>{spotting.display_location}</p>}
              <img src={spotting.image_url} width={"100%"}/>
            </Timeline.Item>
          })}
        </Timeline>
      );
    }
  }